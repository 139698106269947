:root {
	--primary: #71348c;
	--primary1: #347720;
	--primary2: #00a884;
	--color1: #0D6EFD;
	--secondary: #FF6922;
	--light: #EFFDF5;
	--dark: #0E2E50;
}

.shadow {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
}


.profile-tab-nav {
	min-width: 250px;
}

.tab-content {
	flex: 1;
}

.form-group {
	margin-bottom: 1.5rem;
}

.nav-pills a.nav-link {
	padding: 15px 20px;
	border-bottom: 1px solid #ddd;
	border-radius: 0;
	color: #333;
}

.nav-pills a.nav-link i {
	width: 20px;
}

.img-circle img {
	height: 100px;
	width: 100px;
	border-radius: 100%;
	border: 5px solid #646363;
}

.userStudent-profile {
	background-color: aliceblue;

}

.Student-profile-tab {
	background-color: white;
	border-radius: 2%;
	margin-bottom: 2rem;
}

.Student-profile-tab {
	box-shadow: 0 0 5px 2px #a0a0a0;
}

.profile-tab-nav {
	background-color: white;
	box-shadow: 0 0 5px 2px #a0a0a0;
}

/* .super-tag:hover{
	background-color: #FF6922;
	color: black;
} */

.input-cust-div {
	border-radius: 5px;
	background-color: #add8e629;
	padding: 10px;
}